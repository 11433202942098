import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions";
function EnsureLoggedIn(props) {
  var forceLogout = props.forceLogout;
  var navigate = useNavigate();
  useEffect(function () {
    if (!forceLogout) return;
    props.logout().then(function () {
      return navigate("/");
    });
  });
  return props.children;
}
var mapStateToProps = function mapStateToProps(state, ownProps) {
  var forceLogout = state.authenticationStatus.forceLogout;
  return {
    forceLogout: forceLogout
  };
};
export default connect(mapStateToProps, {
  logout: logout
})(EnsureLoggedIn);
EnsureLoggedIn.propTypes = {
  forceLogout: PropTypes.bool,
  logout: PropTypes.func
};