import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// export const primary = ({ theme }) => ({
//   color: "white",
//   boxShadow: "none",
//   textTransform: "none",
//   fontSize: 16,
//   padding: "6px 12px",
//   border: '1px solid',
//   lineHeight: 1.5,
//   backgroundColor: brandPrimary,
//   borderColor: brandPrimary,
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   "&:hover": {
//     color: "white",
//     backgroundColor: brandPrimaryFocus,
//     borderColor: brandPrimaryFocus,
//     boxShadow: "none",
//   },
//   "&:active": {
//     color: "white",
//     boxShadow: "none",
//     backgroundColor: brandPrimaryFocus,
//     borderColor: brandPrimaryFocus,
//   },
//   "&:focus": {
//     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
//   },
// });

var StyledButton = styled(Button, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== "icon";
  }
})(function (_ref) {
  var icon = _ref.icon;
  var style = {
    textTransform: "none",
    borderRadius: "50rem"
  };
  if (icon) {
    style.minWidth = "inherit";
  } else {
    style.paddingRight = "16px";
    style.paddingLeft = "16px";
  }
  return style;
});
StyledButton.defaultProps = {
  disableElevation: true
};
export default StyledButton;