function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_USERS_REQUEST = "GET_USERS_REQUEST";
export var GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export var GET_USERS_FAILURE = "GET_USERS_FAILURE";
export var loadUsers = function loadUsers(page, size, idFilterValue, emailFilterValue, emailFilterMode, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "full",
      idFilterValue: idFilterValue,
      emailFilterValue: emailFilterValue,
      emailFilterMode: emailFilterMode,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({
      page: page,
      size: size
    }, CALL_API, {
      types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
      endpoint: "users/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_USERSBYINVESTMENTACCOUNTBROKERID_REQUEST = "GET_USERSBYINVESTMENTACCOUNTBROKERID_REQUEST";
export var GET_USERSBYINVESTMENTACCOUNTBROKERID_SUCCESS = "GET_USERSBYINVESTMENTACCOUNTBROKERID_SUCCESS";
export var GET_USERSBYINVESTMENTACCOUNTBROKERID_FAILURE = "GET_USERSBYINVESTMENTACCOUNTBROKERID_FAILURE";
export var loadUsersByInvestmentAccountBrokerId = function loadUsersByInvestmentAccountBrokerId(page, size, investmentAccountsBrokerId, idFilterValue, emailFilterValue, emailFilterMode, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "full",
      investmentAccountsBrokerId: investmentAccountsBrokerId,
      idFilterValue: idFilterValue,
      emailFilterValue: emailFilterValue,
      emailFilterMode: emailFilterMode,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({
      investmentAccountsBrokerId: investmentAccountsBrokerId
    }, CALL_API, {
      types: [GET_USERSBYINVESTMENTACCOUNTBROKERID_REQUEST, GET_USERSBYINVESTMENTACCOUNTBROKERID_SUCCESS, GET_USERSBYINVESTMENTACCOUNTBROKERID_FAILURE],
      endpoint: "users/search/findAllByInvestmentAccountBrokerId".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_REQUEST = "GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_REQUEST";
export var GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_SUCCESS = "GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_SUCCESS";
export var GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_FAILURE = "GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_FAILURE";
export var RESET_GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID = "RESET_GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID";

// Fetches users by email containing value and goal from Front API.
// Relies on Redux Thunk middleware.
export var loadUsersByEmailContainingAndMembersGoalId = function loadUsersByEmailContainingAndMembersGoalId(page, size, email, membersGoalId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "withIdAndEmail",
      email: email,
      membersGoalId: membersGoalId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_REQUEST, GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_SUCCESS, GET_USERSBYEMAILCONTAININGANDMEMBERSGOALID_FAILURE],
      endpoint: "users/search/findByEmailContainingAndMembersGoalId".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_USERSBYEMAILCONTAINING_REQUEST = "GET_USERSBYEMAILCONTAINING_REQUEST";
export var GET_USERSBYEMAILCONTAINING_SUCCESS = "GET_USERSBYEMAILCONTAINING_SUCCESS";
export var GET_USERSBYEMAILCONTAINING_FAILURE = "GET_USERSBYEMAILCONTAINING_FAILURE";
export var RESET_GET_USERSBYEMAILCONTAINING = "RESET_GET_USERSBYEMAILCONTAINING";

// Fetches users by email containing value from Front API.
// Relies on Redux Thunk middleware.
export var loadUsersByEmailContaining = function loadUsersByEmailContaining(page, size, email) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "publicWithEmail",
      email: email
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_USERSBYEMAILCONTAINING_REQUEST, GET_USERSBYEMAILCONTAINING_SUCCESS, GET_USERSBYEMAILCONTAINING_FAILURE],
      endpoint: "users/search/findByEmailContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_REQUEST = "GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_REQUEST";
export var GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_SUCCESS = "GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_SUCCESS";
export var GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_FAILURE = "GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_FAILURE";
export var RESET_GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING = "RESET_GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING";

// Fetches users by email containing value from Front API.
// Relies on Redux Thunk middleware.
export var loadUsersByInvestmentAccountBrokerIdAndEmailContaining = function loadUsersByInvestmentAccountBrokerIdAndEmailContaining(page, size, investmentAccountsBrokerId, email) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "publicWithEmail",
      investmentAccountsBrokerId: investmentAccountsBrokerId,
      email: email
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_REQUEST, GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_SUCCESS, GET_USERSBYINVESTMENTACCOUNTSBROKERIDANDEMAILCONTAINING_FAILURE],
      endpoint: "users/search/findByInvestmentAccounts_Broker_IdAndEmailContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_USERSBYUSERNAMESTARTINGWITH_REQUEST = "GET_USERSBYUSERNAMESTARTINGWITH_REQUEST";
export var GET_USERSBYUSERNAMESTARTINGWITH_SUCCESS = "GET_USERSBYUSERNAMESTARTINGWITH_SUCCESS";
export var GET_USERSBYUSERNAMESTARTINGWITH_FAILURE = "GET_USERSBYUSERNAMESTARTINGWITH_FAILURE";
export var RESET_GET_USERSBYUSERNAMESTARTINGWITH = "RESET_GET_USERSBYUSERNAMESTARTINGWITH";

// Fetches users by firstName and lastName containing value from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadUsersByUsernameStartingWith = function loadUsersByUsernameStartingWith(page, size, usernameStartingWith) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      usernameStartingWith: usernameStartingWith
    });
    return dispatch(_defineProperty({
      usernameStartingWith: usernameStartingWith
    }, CALL_API, {
      types: [GET_USERSBYUSERNAMESTARTINGWITH_REQUEST, GET_USERSBYUSERNAMESTARTINGWITH_SUCCESS, GET_USERSBYUSERNAMESTARTINGWITH_FAILURE],
      endpoint: "users/search/findByUsernameStartingWith".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_REQUEST = "GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_REQUEST";
export var GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_SUCCESS = "GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_SUCCESS";
export var GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_FAILURE = "GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_FAILURE";
export var RESET_GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN = "RESET_GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN";

// Fetches users by firstName and lastName containing value from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var fetchUsersByUsernameStartingWithAndUsernameNotIn = function fetchUsersByUsernameStartingWithAndUsernameNotIn(page, size, usernameStartingWith, usernameNotIn) {
  var queryString = urlSearchParams({
    page: page,
    size: size,
    projection: "public",
    usernameStartingWith: usernameStartingWith,
    usernameNotIn: usernameNotIn
  });
  return _defineProperty({
    usernameStartingWith: usernameStartingWith,
    usernameNotIn: usernameNotIn
  }, CALL_API, {
    types: [GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_REQUEST, GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_SUCCESS, GET_USERSBYUSERNAMESTARTINGWITHANDUSERNAMENOTIN_FAILURE],
    endpoint: "users/search/findByUsernameStartingWithAndUsernameNotIn".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches users by firstName and lastName containing value from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadUsersByUsernameStartingWithAndUsernameNotIn = function loadUsersByUsernameStartingWithAndUsernameNotIn(page, size, usernameStartingWith, usernameNotIn) {
  return function (dispatch, getState) {
    return dispatch(fetchUsersByUsernameStartingWithAndUsernameNotIn(page, size, usernameStartingWith, usernameNotIn));
  };
};
export var GET_USERBYID_REQUEST = "GET_USERBYID_REQUEST";
export var GET_USERBYID_SUCCESS = "GET_USERBYID_SUCCESS";
export var GET_USERBYID_FAILURE = "GET_USERBYID_FAILURE";
export var RESET_GET_USERBYID = "RESET_GET_USERBYID";

// Fetches a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchUserById = function fetchUserById(id) {
  var queryString = urlSearchParams({
    projection: "full"
  });
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_USERBYID_REQUEST, GET_USERBYID_SUCCESS, GET_USERBYID_FAILURE],
    endpoint: "users/".concat(id).concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches a particular user by email from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadUserById = function loadUserById(id, forceUpdate) {
  return function (dispatch, getState) {
    var user = getState().entities.users.byId[id];
    if (user && !forceUpdate) return null;
    var call = getState().apiCalls.loadUserById[id];
    if (call && call.isFetching) return null;
    return dispatch(fetchUserById(id));
  };
};
export var GET_USERBYTOKEN_REQUEST = "GET_USERBYTOKEN_REQUEST";
export var GET_USERBYTOKEN_SUCCESS = "GET_USERBYTOKEN_SUCCESS";
export var GET_USERBYTOKEN_FAILURE = "GET_USERBYTOKEN_FAILURE";
export var RESET_GET_USERBYTOKEN = "RESET_GET_USERBYTOKEN";

// Fetches the logged user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchUserByToken = function fetchUserByToken(token) {
  return _defineProperty({
    token: token
  }, CALL_API, {
    types: [GET_USERBYTOKEN_REQUEST, GET_USERBYTOKEN_SUCCESS, GET_USERBYTOKEN_FAILURE],
    endpoint: "users/search/findByToken?token=".concat(token, "&projection=full"),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches a particular user by token from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadUserByToken = function loadUserByToken(token) {
  return function (dispatch, getState) {
    var user = getState().entities.users.byToken[token];
    if (user) return null;
    return dispatch(fetchUserByToken(token));
  };
};
export var POST_USER_REQUEST = "POST_USER_REQUEST";
export var POST_USER_SUCCESS = "POST_USER_SUCCESS";
export var POST_USER_FAILURE = "POST_USER_FAILURE";
export var RESET_POST_USER = "RESET_POST_USER";
export var postUser = function postUser(user) {
  return _defineProperty({
    id: user.id,
    email: user.email
  }, CALL_API, {
    types: [POST_USER_REQUEST, POST_USER_SUCCESS, POST_USER_FAILURE],
    endpoint: "users/".concat(user.id, "?lang=es"),
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user),
      credentials: "same-origin"
    }
  });
};
export var postUserFailure = function postUserFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_USER_FAILURE
    });
  };
};
export var resetPostUser = function resetPostUser() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_USER
    });
  };
};
export var POST_USERPROFILEPICTURE_REQUEST = "POST_USERPROFILEPICTURE_REQUEST";
export var POST_USERPROFILEPICTURE_SUCCESS = "POST_USERPROFILEPICTURE_SUCCESS";
export var POST_USERPROFILEPICTURE_FAILURE = "POST_USERPROFILEPICTURE_FAILURE";
export var RESET_POST_USERPROFILEPICTURE = "RESET_POST_USERPROFILEPICTURE";
export var postUserProfilePicture = function postUserProfilePicture(user, profilePicture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      user: user,
      profilePicture: profilePicture
    }, CALL_API, {
      types: [POST_USERPROFILEPICTURE_REQUEST, POST_USERPROFILEPICTURE_SUCCESS, POST_USERPROFILEPICTURE_FAILURE],
      endpoint: "users/".concat(user.id, "/profilePicture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(profilePicture),
        credentials: "same-origin"
      }
    }));
  };
};
export var postUserProfilePictureFailure = function postUserProfilePictureFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_USERPROFILEPICTURE_FAILURE
    });
  };
};
export var resetPostUserProfilePicture = function resetPostUserProfilePicture(userId) {
  return function (dispatch, getState) {
    return dispatch({
      userId: userId,
      type: RESET_POST_USERPROFILEPICTURE
    });
  };
};
export var POST_USERPROFILEBACKGROUNDPICTURE_REQUEST = "POST_USERPROFILEBACKGROUNDPICTURE_REQUEST";
export var POST_USERPROFILEBACKGROUNDPICTURE_SUCCESS = "POST_USERPROFILEBACKGROUNDPICTURE_SUCCESS";
export var POST_USERPROFILEBACKGROUNDPICTURE_FAILURE = "POST_USERPROFILEBACKGROUNDPICTURE_FAILURE";
export var RESET_POST_USERPROFILEBACKGROUNDPICTURE = "RESET_POST_USERPROFILEBACKGROUNDPICTURE";
export var postUserProfileBackgroundPicture = function postUserProfileBackgroundPicture(user, profileBackgroundPicture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      user: user,
      profileBackgroundPicture: profileBackgroundPicture
    }, CALL_API, {
      types: [POST_USERPROFILEBACKGROUNDPICTURE_REQUEST, POST_USERPROFILEBACKGROUNDPICTURE_SUCCESS, POST_USERPROFILEBACKGROUNDPICTURE_FAILURE],
      endpoint: "users/".concat(user.id, "/profileBackgroundPicture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(profileBackgroundPicture),
        credentials: "same-origin"
      }
    }));
  };
};
export var postUserProfileBackgroundPictureFailure = function postUserProfileBackgroundPictureFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_USERPROFILEBACKGROUNDPICTURE_FAILURE
    });
  };
};
export var resetPostUserProfileBackgroundPicture = function resetPostUserProfileBackgroundPicture(userId) {
  return function (dispatch, getState) {
    return dispatch({
      userId: userId,
      type: RESET_POST_USERPROFILEBACKGROUNDPICTURE
    });
  };
};
export var POST_USERADDRESSES_REQUEST = "POST_USERADDRESSES_REQUEST";
export var POST_USERADDRESSES_SUCCESS = "POST_USERADDRESSES_SUCCESS";
export var POST_USERADDRESSES_FAILURE = "POST_USERADDRESSES_FAILURE";
export var RESET_POST_USERADDRESSES = "RESET_POST_USERADDRESSES";
export var postUserAddresses = function postUserAddresses(id, addresses) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      id: id,
      addresses: addresses
    }, CALL_API, {
      types: [POST_USERADDRESSES_REQUEST, POST_USERADDRESSES_SUCCESS, POST_USERADDRESSES_FAILURE],
      endpoint: "users/".concat(id, "/addresses"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(addresses),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostUserAddresses = function resetPostUserAddresses(userId) {
  return function (dispatch, getState) {
    return dispatch({
      userId: userId,
      type: RESET_POST_USERADDRESSES
    });
  };
};
export var POST_USERBANKACCOUNTS_REQUEST = "POST_USERBANKACCOUNTS_REQUEST";
export var POST_USERBANKACCOUNTS_SUCCESS = "POST_USERBANKACCOUNTS_SUCCESS";
export var POST_USERBANKACCOUNTS_FAILURE = "POST_USERBANKACCOUNTS_FAILURE";
export var RESET_POST_USERBANKACCOUNTS = "RESET_POST_USERBANKACCOUNTS";
export var postUserBankAccounts = function postUserBankAccounts(id, bankAccounts) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      id: id,
      bankAccounts: bankAccounts
    }, CALL_API, {
      types: [POST_USERBANKACCOUNTS_REQUEST, POST_USERBANKACCOUNTS_SUCCESS, POST_USERBANKACCOUNTS_FAILURE],
      endpoint: "users/".concat(id, "/bankAccounts"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bankAccounts),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostUserBankAccounts = function resetPostUserBankAccounts(userId) {
  return function (dispatch, getState) {
    return dispatch({
      userId: userId,
      type: RESET_POST_USERBANKACCOUNTS
    });
  };
};
export var POST_USEREXCLUSIONPREFERENCES_REQUEST = "POST_USEREXCLUSIONPREFERENCES_REQUEST";
export var POST_USEREXCLUSIONPREFERENCES_SUCCESS = "POST_USEREXCLUSIONPREFERENCES_SUCCESS";
export var POST_USEREXCLUSIONPREFERENCES_FAILURE = "POST_USEREXCLUSIONPREFERENCES_FAILURE";
export var RESET_POST_USEREXCLUSIONPREFERENCES = "RESET_POST_USEREXCLUSIONPREFERENCES";
export var postUserExclusionPreferences = function postUserExclusionPreferences(user, exclusionPreferences) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      user: user,
      exclusionPreferences: exclusionPreferences
    }, CALL_API, {
      types: [POST_USEREXCLUSIONPREFERENCES_REQUEST, POST_USEREXCLUSIONPREFERENCES_SUCCESS, POST_USEREXCLUSIONPREFERENCES_FAILURE],
      endpoint: "users/".concat(user.id, "/exclusionPreferences"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(exclusionPreferences),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostUserExclusionPreferences = function resetPostUserExclusionPreferences(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_USEREXCLUSIONPREFERENCES
    });
  };
};
export var POST_USERLANGUAGEPREFERENCES_REQUEST = "POST_USERLANGUAGEPREFERENCES_REQUEST";
export var POST_USERLANGUAGEPREFERENCES_SUCCESS = "POST_USERLANGUAGEPREFERENCES_SUCCESS";
export var POST_USERLANGUAGEPREFERENCES_FAILURE = "POST_USERLANGUAGEPREFERENCES_FAILURE";
export var RESET_POST_USERLANGUAGEPREFERENCES = "RESET_POST_USERLANGUAGEPREFERENCES";
export var postUserLanguagePreferences = function postUserLanguagePreferences(user, languagePreferences) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      user: user,
      languagePreferences: languagePreferences
    }, CALL_API, {
      types: [POST_USERLANGUAGEPREFERENCES_REQUEST, POST_USERLANGUAGEPREFERENCES_SUCCESS, POST_USERLANGUAGEPREFERENCES_FAILURE],
      endpoint: "users/".concat(user.id, "/languagePreferences"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(languagePreferences),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostUserLanguagePreferences = function resetPostUserLanguagePreferences(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_USERLANGUAGEPREFERENCES
    });
  };
};
export var GET_USEREXISTSBYUSERNAME_REQUEST = "GET_USEREXISTSBYUSERNAME_REQUEST";
export var GET_USEREXISTSBYUSERNAME_SUCCESS = "GET_USEREXISTSBYUSERNAME_SUCCESS";
export var GET_USEREXISTSBYUSERNAME_FAILURE = "GET_USEREXISTSBYUSERNAME_FAILURE";
export var RESET_GET_USEREXISTSBYUSERNAME = "RESET_GET_USEREXISTSBYUSERNAME";
export var loadUserExistsByUsername = function loadUserExistsByUsername(username) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      username: username
    }, CALL_API, {
      types: [GET_USEREXISTSBYUSERNAME_REQUEST, GET_USEREXISTSBYUSERNAME_SUCCESS, GET_USEREXISTSBYUSERNAME_FAILURE],
      endpoint: "users/search/existsByUsername?username=".concat(username),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_REQUESTDELETEUSER_REQUEST = "GET_REQUESTDELETEUSER_REQUEST";
export var GET_REQUESTDELETEUSER_SUCCESS = "GET_REQUESTDELETEUSER_SUCCESS";
export var GET_REQUESTDELETEUSER_FAILURE = "GET_REQUESTDELETEUSER_FAILURE";
export var RESET_GET_REQUESTDELETEUSER = "RESET_GET_REQUESTDELETEUSER";
export var requestDeleteUser = function requestDeleteUser(userId) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      userId: userId
    }, CALL_API, {
      types: [GET_REQUESTDELETEUSER_REQUEST, GET_REQUESTDELETEUSER_SUCCESS, GET_REQUESTDELETEUSER_FAILURE],
      endpoint: "users/".concat(userId, "/requestDelete"),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var resetRequestDeleteUser = function resetRequestDeleteUser() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_GET_REQUESTDELETEUSER
    });
  };
};
export var GET_CONFIRMDELETEUSER_REQUEST = "GET_CONFIRMDELETEUSER_REQUEST";
export var GET_CONFIRMDELETEUSER_SUCCESS = "GET_CONFIRMDELETEUSER_SUCCESS";
export var GET_CONFIRMDELETEUSER_FAILURE = "GET_CONFIRMDELETEUSER_FAILURE";
export var RESET_GET_CONFIRMDELETEUSER = "RESET_GET_CONFIRMDELETEUSER";
export var confirmDeleteUser = function confirmDeleteUser(userId, token) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      userId: userId
    }, CALL_API, {
      types: [GET_CONFIRMDELETEUSER_REQUEST, GET_CONFIRMDELETEUSER_SUCCESS, GET_CONFIRMDELETEUSER_FAILURE],
      endpoint: "users/".concat(userId, "/confirmDelete?token=").concat(token),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var resetConfirmDeleteUser = function resetConfirmDeleteUser() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_GET_CONFIRMDELETEUSER
    });
  };
};