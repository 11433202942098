import * as ActionTypes from "../actions";
import merge from "lodash/merge";
import { roleIds } from "../constants";
var authenticationStatus = function authenticationStatus() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    isLoggedIn: null,
    isAdmin: null,
    userId: null,
    redirectUrl: null,
    forceLogout: false
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return merge({}, state, {
        isLoggedIn: action.response.loggedIn,
        isAdmin: action.response.authorities && action.response.authorities.some(function (a) {
          return a.authority == "ROLE_ADMIN";
        }),
        userId: action.response.principal && action.response.principal.id,
        forceLogout: false
      });
    case ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS:
      return merge({}, state, {
        isLoggedIn: action.response.loggedIn,
        isAdmin: action.response.authorities && action.response.authorities.some(function (a) {
          return a.authority == "ROLE_ADMIN";
        }),
        userId: action.response.principal && action.response.principal.id
      });
    case ActionTypes.SET_AUTHENTICATIONSTATUS:
      return merge({}, state, action.authenticationStatus);
    case ActionTypes.SETREDIRECTURL:
      return merge({}, state, {
        redirectUrl: action.url
      });
    case ActionTypes.LOGIN_FAILURE:
      return merge({}, state, {
        isLoggedIn: false,
        isAdmin: null,
        userId: null
      });
    default:
      if (/^[A-Z._%+-]+\_FAILURE$/i.test(action.type)) return merge({}, state, {
        forceLogout: action.forceLogout
      });else return state;
  }
};
export default authenticationStatus;