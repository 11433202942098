function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";
import { operationStates, memberStates } from "../../constants";

// Updates an entity cache in response to any action with response.entities.
var operationEntities = function operationEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byCurrencyIdAndGoalIdAndUserIdAndStateIn: {},
    byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: {},
    byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: {},
    byBrokerIdAndGoalIdAndUserIdAndStateIn: {},
    byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: {},
    byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: {},
    byGoalIdAndUserIdAndStateInAndSubmittedAtAfter: {},
    byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot: {},
    byUserIdAndStateNot: {},
    byId: {},
    byBrokerId: {},
    byGoalIdAndUserIdAndStateIn: {},
    byUserIdAndStateIn: {},
    byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIn: {},
    depositsByUserIdAndState: {},
    rebalance: {},
    paymentsByUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_OPERATIONBYID_SUCCESS:
      if (!action.response) return state;
      var byId = _objectSpread({}, state.byId);
      byId[action.id] = action.response;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS:
      var key = action.response.principal && action.response.principal.id;
      var notificationListItems = action.response.notificationListItemsByUserId[key];
      if (!notificationListItems) return state;
      var byId = _objectSpread({}, state.byId);
      notificationListItems.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS:
    case ActionTypes.GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.operationById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        return merge(byId, eventListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (eventListItem) {
        return merge(byId, eventListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS:
      var byBrokerIdAndGoalIdAndUserIdAndStateIn = _objectSpread({}, state.byBrokerIdAndGoalIdAndUserIdAndStateIn);
      var key = [action.brokerId, action.goalId || "null", action.userId, action.states].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byBrokerIdAndGoalIdAndUserIdAndStateIn[key] = embedded;
      return Object.assign({}, state, {
        byBrokerIdAndGoalIdAndUserIdAndStateIn: byBrokerIdAndGoalIdAndUserIdAndStateIn
      });
    case ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS:
      var byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan);
      var key = [action.brokerId, action.currencyId, action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan[key] = embedded;
      return Object.assign({}, state, {
        byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan
      });
    case ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS:
      var byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan = _objectSpread({}, state.byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan);
      var key = [action.brokerId, action.currencyId, action.goalId || "null", action.investmentItemId, action.userId, action.states, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan[key] = embedded;
      return Object.assign({}, state, {
        byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan
      });
    case ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS:
      var byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan);
      var key = [action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan[key] = embedded;
      return Object.assign({}, state, {
        byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan
      });
    case ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS:
      var byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan);
      var key = [action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan[key] = embedded;
      return Object.assign({}, state, {
        byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan
      });
    case ActionTypes.GET_DEPOSITSBYUSERIDANDSTATE_SUCCESS:
      var depositsByUserIdAndState = _objectSpread({}, state.depositsByUserIdAndState);
      var key = [action.userId, action.state].join("-");
      if (depositsByUserIdAndState[key] && action.sinceId !== undefined) depositsByUserIdAndState[key] = action.response.reverse().concat(depositsByUserIdAndState[key]);else if (depositsByUserIdAndState[key] && action.maxId !== undefined) depositsByUserIdAndState[key] = depositsByUserIdAndState[key].concat(action.response);else depositsByUserIdAndState[key] = action.response;
      return Object.assign({}, state, {
        depositsByUserIdAndState: depositsByUserIdAndState
      });
    case ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS:
      var byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot = _objectSpread({}, state.byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot);
      var key = [action.userId, action.states].join("-");
      if (byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key] && action.sinceId !== undefined) byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key] = action.response.reverse().concat(byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key]);else if (byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key] && action.maxId !== undefined) byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key] = byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key].concat(action.response);else byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key] = action.response;
      var byId = _objectSpread({}, state.byId);
      action.response && action.response.forEach(function (operation) {
        return byId[operation.id] = operation;
      });
      return Object.assign({}, state, {
        byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot: byUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot,
        byId: byId
      });
    case ActionTypes.GET_OPERATIONS_SUCCESS:
    case ActionTypes.GET_OPERATIONSBYBROKERID_SUCCESS:
      var embedded = action.response._embedded;
      if (!embedded) return state;
      var byId = _objectSpread({}, state.byId);
      embedded.operations.forEach(function (operation) {
        return byId[operation.id] = operation;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_OPERATION_SUCCESS:
      var embedded = action.response._embedded;
      if (!embedded) return state;
      var byId = _objectSpread({}, state.byId);
      byId[embedded.id] = embedded;
      var byUserIdAndStateIn = _objectSpread({}, state.byUserIdAndStateIn);
      var engagedOperationStates = [operationStates.PENDING, operationStates.SUBMITTED].join(",");
      var key = [embedded.user.id, engagedOperationStates].join("-");
      var engagedOperations = byUserIdAndStateIn[key];
      if ((embedded.state === operationStates.PENDING || embedded.state === operationStates.SUBMITTED) && !engagedOperations) byUserIdAndStateIn[key] = [embedded];else if ((embedded.state === operationStates.PENDING || embedded.state === operationStates.SUBMITTED) && !engagedOperations.find(function (operation) {
        return operation.id === embedded.id;
      })) engagedOperations.push(embedded);
      return Object.assign({}, state, {
        byId: byId,
        byUserIdAndStateIn: byUserIdAndStateIn
      });
    case ActionTypes.POST_OPERATIONS_SUCCESS:
      var embedded = action.response._embedded;
      if (!embedded) return state;
      var byId = _objectSpread({}, state.byId);
      embedded.forEach(function (operation) {
        return byId[operation.id] = operation;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.DELETE_OPERATION_SUCCESS:
      var byCurrencyIdAndGoalIdAndUserIdAndStateIn = _objectSpread({}, state.byCurrencyIdAndGoalIdAndUserIdAndStateIn);
      Object.keys(state.byCurrencyIdAndGoalIdAndUserIdAndStateIn).map(function (key) {
        byCurrencyIdAndGoalIdAndUserIdAndStateIn[key] = state.byCurrencyIdAndGoalIdAndUserIdAndStateIn[key] && state.byCurrencyIdAndGoalIdAndUserIdAndStateIn[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan);
      Object.keys(state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan).map(function (key) {
        byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan[key] = state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan);
      Object.keys(state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan).map(function (key) {
        byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan[key] = state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan);
      Object.keys(state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan).map(function (key) {
        byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan[key] = state.byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byUserIdAndStateNot = _objectSpread({}, state.byUserIdAndStateNot);
      Object.keys(state.byUserIdAndStateNot).map(function (key) {
        byUserIdAndStateNot[key] = state.byUserIdAndStateNot[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byId = _objectSpread({}, state.byId);
      delete byId[action.operation.id];
      var byBrokerId = _objectSpread({}, state.byBrokerId);
      Object.keys(state.byBrokerId).map(function (key) {
        byId[byBrokerId] = state.byId[byBrokerId].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      return Object.assign({}, state, {
        byCurrencyIdAndGoalIdAndUserIdAndStateIn: byCurrencyIdAndGoalIdAndUserIdAndStateIn,
        byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan,
        byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan,
        byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: byGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan,
        byUserIdAndStateNot: byUserIdAndStateNot,
        byId: byId,
        byBrokerId: byBrokerId
      });
    case ActionTypes.GET_MEMBERSVIEWBYUSERID_SUCCESS:
      var byGoalIdAndUserIdAndStateInAndSubmittedAtAfter = _objectSpread({}, state.byGoalIdAndUserIdAndStateInAndSubmittedAtAfter);
      var nonFinalizedMemberStates = [memberStates.DELETABLE, memberStates.ONGOING, memberStates.PENDING_FINALIZE].join(",");
      var key = [action.userId, nonFinalizedMemberStates].join("-");
      action.response.memberListItemsByMemberUserIdAndMemberStateIn[key].forEach(function (memberListItem) {
        return merge(byGoalIdAndUserIdAndStateInAndSubmittedAtAfter, memberListItem.operationsByGoalIdAndUserIdAndStateInAndSubmittedAtAfter);
      });
      return merge({}, state, {
        byGoalIdAndUserIdAndStateInAndSubmittedAtAfter: byGoalIdAndUserIdAndStateInAndSubmittedAtAfter
      });
    case ActionTypes.GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_SUCCESS:
      var byCurrencyIdAndGoalIdAndUserIdAndStateInAndSubmittedAtAfter = _objectSpread({}, state.byCurrencyIdAndGoalIdAndUserIdAndStateInAndSubmittedAtAfter);
      action.response.forEach(function (card) {
        return merge(byCurrencyIdAndGoalIdAndUserIdAndStateInAndSubmittedAtAfter, card.operationsByCurrencyIdAndGoalIdAndUserIdAndStateInAndSubmittedAtAfter);
      });
      return merge({}, state, {
        byCurrencyIdAndGoalIdAndUserIdAndStateInAndSubmittedAtAfter: byCurrencyIdAndGoalIdAndUserIdAndStateInAndSubmittedAtAfter
      });
    case ActionTypes.GET_DISTRIBUTIONTABBYGOALIDANDUSERID_SUCCESS:
      var byGoalIdAndUserIdAndStateIn = action.response.operationsByGoalIdAndUserIdAndStateIn;
      return merge({}, state, {
        byGoalIdAndUserIdAndStateIn: byGoalIdAndUserIdAndStateIn
      });
    case ActionTypes.GET_CARTVIEWBYUSERIDANDOPERATIONSTATE_SUCCESS:
    case ActionTypes.GET_WALLETVIEWBYUSERID_SUCCESS:
      var byUserIdAndStateIn = action.response.operationsByUserIdAndStateIn;
      return merge({}, state, {
        byUserIdAndStateIn: byUserIdAndStateIn
      });
    case ActionTypes.GET_OPERATIONFORMBYINVESTMENTITEMIDANDUSERID_SUCCESS:
      var byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIn = action.response.operationsByCurrencyIdAndInvestmentItemIdAndUserIdAndStateIn;
      return merge({}, state, {
        byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIn: byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIn
      });

    // case ActionTypes.GET_PENDINGDEPOSITSVIEWBYUSERID_SUCCESS:
    //   var depositsByUserIdAndState = action.response.depositsByUserIdAndState;
    //   return Object.assign({}, state, { depositsByUserIdAndState });

    case ActionTypes.GET_USERVIEWBYFOLLOWEEUSERNAMEANDAUTHUSERID_SUCCESS:
      var paymentsByUserId = action.response.paymentOperationsByUserId;
      return merge({}, state, {
        paymentsByUserId: paymentsByUserId
      });
    default:
      return state;
  }
};
export default operationEntities;