import React from "react";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
var StyledLoadingButton = styled(LoadingButton, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== "icon";
  }
})(function (_ref) {
  var icon = _ref.icon,
    color = _ref.color,
    activeColor = _ref.activeColor,
    theme = _ref.theme;
  var style = {
    textTransform: "none",
    borderRadius: "50rem"
    // "&.active": {
    //   color: theme.palette[activeColor || "primary"].main,
    //   // backgroundColor: theme.palette[color || "primary"].main,
    // },
  };

  if (icon) style.minWidth = "inherit";
  return style;
});
StyledLoadingButton.defaultProps = {
  disableElevation: true
};
export default StyledLoadingButton;