import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var priceApiCalls = {
  postPrice: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_PRICE_REQUEST, ActionTypes.POST_PRICE_SUCCESS, ActionTypes.POST_PRICE_FAILURE, ActionTypes.RESET_POST_PRICE]
  }),
  collectPricesFromCafci: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTPRICESFROMCAFCI_REQUEST, ActionTypes.COLLECTPRICESFROMCAFCI_SUCCESS, ActionTypes.COLLECTPRICESFROMCAFCI_FAILURE, ActionTypes.RESET_COLLECTPRICESFROMCAFCI]
  }),
  abortCollectPricesFromCafci: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTPRICESFROMCAFCI_REQUEST, ActionTypes.ABORTCOLLECTPRICESFROMCAFCI_SUCCESS, ActionTypes.ABORTCOLLECTPRICESFROMCAFCI_FAILURE, ActionTypes.RESET_ABORTCOLLECTPRICESFROMCAFCI]
  }),
  collectPricesFromInvertirOnline: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTPRICESFROMINVERTIRONLINE_REQUEST, ActionTypes.COLLECTPRICESFROMINVERTIRONLINE_SUCCESS, ActionTypes.COLLECTPRICESFROMINVERTIRONLINE_FAILURE, ActionTypes.RESET_COLLECTPRICESFROMINVERTIRONLINE]
  }),
  abortCollectPricesFromInvertirOnline: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTPRICESFROMINVERTIRONLINE_REQUEST, ActionTypes.ABORTCOLLECTPRICESFROMINVERTIRONLINE_SUCCESS, ActionTypes.ABORTCOLLECTPRICESFROMINVERTIRONLINE_FAILURE, ActionTypes.RESET_ABORTCOLLECTPRICESFROMINVERTIRONLINE]
  }),
  loadPricesByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.investmentItemId, action.currencyId, action.brokerId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_PRICESBYCURRENCYIDANDINVESTMENTITEMIDANDINSTANTBETWEEN]
  }),
  fetchPricesByInvestmentItemIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEEN]
  })
};
export default priceApiCalls;